import React from "react"
import { Link, graphql } from "gatsby"

import Pagination from "../components-tr/pagination.tr"
import Layout from "../components/layout"
import Seo from "../components-tr/seo"
import Article from "../components-tr/article.tr"

const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath, currentPagePath, totalPages, pageNumber },
}) => {
  const posts = data.allWpPost.nodes
  const categories = data.allWpCategory.nodes

  const filterCategories = categories.filter(category => category.posts.nodes.length)

  const metaTitle = pageNumber === 1 ? `Blog | Son Gelişmeler ve Hikayeler` : `Blog | ${pageNumber} | Son Gelişmeler ve Hikayeler`
  const metaDescription = pageNumber === 1 ? `tio Blog | tio'dan dijital ürünlerde son gelişmeler, haberler, kaynaklar ve hikayeler yazılar.` : `tio Blog ${pageNumber} | tio'dan dijital ürünlerde son gelişmeler, haberler, kaynaklar ve hikayeler yazılar. ${pageNumber}. Sayfa.`

  if (!posts.length) {
    return (
      <Layout navbarTheme="dark" className="bg-cream">
        <Seo title={metaTitle} description={metaDescription} canonical="https://tio.ist/tr/blog/" />
        <p>İçerik bulunamadı.</p>
      </Layout>
    )
  }

  //Son gelişmeler, hikayeler ve içerikler.

  return (
    <Layout navbarTheme="dark" className="bg-cream">
      <Seo title={metaTitle} description={metaDescription} canonical="https://tio.ist/tr/blog/" />
      <div className="py-10">
        <div className="text-blue pt-10">
          <h1 className="text-6xl md:text-8xl lg:text-9xl font-extrabold text-center mb-6">
            SON GELİŞMELER,
          </h1>

          <div className="flex overflow-hidden text-blue !leading-tight text-6xl md:text-8xl lg:text-9xl font-light italic">
            <div className="flex items-center flex-none min-w-[150%] animate-scroll-left">
              <p>HİKAYELER VE İÇERİKLER</p>
            </div>
            <div className="flex items-center flex-none min-w-[150%] animate-scroll-left">
              <p>HİKAYELER VE İÇERİKLER</p>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap gap-x-12 gap-y-6 mx-5 md:mx-16 pt-40">
          {filterCategories.map(category => <Link lang="tr" to={`/tr/blog/category/${category.slug}`} key={category.id} className="text-blue uppercase font-bold text-sm" activeClassName="underline">{category.name}</Link>)}
        </div>
      </div>
      <div className="mx-2.5 md:mx-[3.375rem]">
        <ol className="lg:flex flex-wrap list-none">
          {posts.map((post, index) => {
            const title = post.title
            const featuredImage = {
              data: post.featuredImage?.node?.localFile?.childImageSharp
                ?.gatsbyImageData,
              alt: post.featuredImage?.node?.alt || ``,
            }
            return (
              <Article key={index} post={post} title={title} featuredImage={featuredImage} />
            )
          })}
        </ol>
      </div>

      <Pagination
        rootSlug="/tr/blog"
        totalPages={totalPages}
        previousPagePath={previousPagePath}
        nextPagePath={nextPagePath}
        currentPagePath={currentPagePath}
      />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchiveTr(
    $offset: Int!
    $postsPerPage: Int!
    $language: String!
  ) {
    allWpPost(
      filter: { language: { slug: { eq: $language } } }
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY", locale: $language)
        title
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
    allWpCategory(filter: {language: {slug: {eq: $language}}, posts: {nodes: {elemMatch: {language: {slug: {eq: $language}}}}}}) {
      nodes {
        slug
        id
        name
        posts {
          nodes {
            id
            title
          }
        }
      }
    }
  }
`
